<script>
export default {
  name: "BusinessSegment"
};
</script>

<template>
  <div class="business_segment">
    <div class="title_comm_warp">
      <div class="title_comm_div_warp">
        <div class="content_comm_warp">
          {{ this.$t("home.businessSegment.title") }}
        </div>
        <div class="content_but_comm_warp" />
      </div>
    </div>
    <div class="padding-comm-warp business_segment_content_warp">
      <div class="left_warp">
<!--        艺术品质押-->
        <div class="pledge_warp"> {{ this.$t("home.businessSegment.pledge") }}</div>

        <div class="left_item">
          <!--        艺术品典当-->
          <div class="iteme1">{{ this.$t("home.businessSegment.pawn") }}</div>
          <!--        艺术品信托-->
          <div class="item iteme2">{{ this.$t("home.businessSegment.trust") }}</div>
        </div>
        <div class="left_item">
          <!--        艺术品保险-->
          <div class="iteme2">{{ this.$t("home.businessSegment.insurance") }}</div>
          <!--        金融化咨询-->
          <div class="item iteme1">{{ this.$t("home.businessSegment.consult") }}</div>
        </div>
        <div class="left_item">
          <!--        艺术品租赁-->
          <div class="iteme1">{{ this.$t("home.businessSegment.lease") }}</div>
          <!--        艺术证券化-->
          <div class="item iteme2">{{ this.$t("home.businessSegment.securitization") }}</div>
        </div>
      </div>
      <!--      <div class="right_warp"></div>-->
      <div class="group_12 flex-col">
        <div class="text-wrapper_12 flex-col">
          <!--        艺术品产权交易-->
          <span class="text_41">{{ this.$t("home.businessSegment.equityTransaction") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/variables.scss";
@import "index";

.business_segment {
  width: 100%;
  min-width: $min-width;
  min-height: 700px;
  background: url("../../assets/home/bg/business.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  font-family: SiYuanSongTiRegular;
  .business_segment_content_warp {
    display: flex;
    .left_warp {
      width: 44%;
      color: #ffffff;
      text-align: center;
      //font-size: 1.4rem;
      .pledge_warp {
        background: url("../../assets/home/business_pledge.png") no-repeat;
        background-size: cover; /* 背景图片覆盖整个容器 */
        background-position: center; /* 背景图片居中 */
        cursor: pointer;
      }
      .pledge_warp:hover {
        transform: scale(1.06);
        transition: all 1s;
      }
      .left_item {
        margin-top: 10px;
        display: flex;
        cursor: pointer;
        .item {
          margin-left: 10px;
        }
        .iteme1 {
          width: 50%;
          background: url("../../assets/home/business_js.png") no-repeat;
          background-size: cover; /* 背景图片覆盖整个容器 */
          background-position: center; /* 背景图片居中 */
        }
        .iteme1:hover {
          transform: scale(1.06);
          transition: all 1s;
        }
        .iteme2 {
          width: 50%;
          background: url("../../assets/home/business_hs.png") no-repeat;
          background-size: cover; /* 背景图片覆盖整个容器 */
          background-position: center; /* 背景图片居中 */
        }
        .iteme2:hover {
          transform: scale(1.06);
          transition: all 1s;
        }
      }
    }
    //.right_warp {
    //  margin-left: 10px;
    //  width: 54%;
    //  background: url("../../assets/home/business_right.png") no-repeat;
    //  background-size: cover; /* 背景图片覆盖整个容器 */
    //  background-position: center; /* 背景图片居中 */
    //  cursor: pointer;
    //}
    //.right_warp:hover{
    //  transform: scale(1.06);
    //  transition: all 1s;
    //}

    .group_12 {
      width: 54%;
      background: url("../../assets/home/business_right.png") 0px -1px no-repeat;
      background-size: cover;
    }
    .group_12:hover {
      transform: scale(1.06);
      transition: all 1s;
    }

    .text-wrapper_12 {
      background-color: rgba(255, 255, 255, 0.5);
      height: 97px;
      line-height: 97px;
      margin-top: 28%;
    }

    .text_41 {
      width: 168px;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(163, 31, 36, 1);
      font-size: 24px;
      font-family: SiYuanSongTiRegular;
      font-weight: normal;
      text-align: justify;
      white-space: nowrap;
      line-height: 36px;
      margin: 32px 0 0 327px;
    }
  }
}

/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1280px) {
  .left_warp {
    font-size: 1.4rem;
  }
  .pledge_warp {
    height: $pledgeHeight1920;
    line-height: $pledgeLineHeight1920;
  }
  .iteme1,
  .iteme2 {
    height: $pledgeHeight1920;
    line-height: $pledgeLineHeight1920;
  }
  .text-wrapper_12 {
    height: 524px;
  }
  .business_segment {
    height: 736px;
  }
}
/*最小屏幕分辨率为1920px*/
@media (min-width: 1281px) and (max-width: 1920px) {
  .left_warp {
    font-size: 1.4rem;
    .pledge_warp:hover,
    .iteme1:hover,
    .iteme2:hover {
      transition: width 0.5s ease, height 0.3s ease-out;
      font-size: 1.5rem;
    }
  }
  .pledge_warp {
    height: $pledgeHeight1920;
    line-height: $pledgeLineHeight1920;
  }
  .iteme1,
  .iteme2 {
    height: $pledgeHeight1920;
    line-height: $pledgeLineHeight1920;
  }
  .text-wrapper_12 {
    height: 524px;
  }
  .business_segment {
    height: 732px;
  }
}
/*最小屏幕分辨率为2560px*/
@media (min-width: 1921px) {
  .left_warp {
    font-size: 1.8rem;
    .pledge_warp:hover,
    item1:hover,
    item2:hover {
      transition: width 0.5s ease, height 0.3s ease-out;
      font-size: 1.9rem;
    }
  }
  .pledge_warp {
    height: $pledgeHeight2560;
    line-height: $pledgeLineHeight2560;
  }
  .iteme1,
  .iteme2 {
    height: $pledgeHeight2560;
    line-height: $pledgeLineHeight2560;
  }
  .text-wrapper_12 {
    height: 700px;
  }
  .business_segment {
    height: 920px;
  }
}
</style>
