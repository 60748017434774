<script>
export default {
  name: "Banner",
  components: {},
  data() {
    return {
      activeBadge: 1
    };
  },
  mounted() {
    // 将Vue实例的this赋值给一个变量
    const self = this;

    // 设置定时器
    this.intervalId = window.setInterval(function() {
      const currentActiveBadge = self.activeBadge;
      if (currentActiveBadge === 5) {
        self.activeBadge = 1;
      } else {
        self.activeBadge = self.activeBadge + 1;
      }
    }, 5000);
  },
  methods: {},
  beforeDestroy() {
    // 清除定时器，防止内存泄露
    window.clearInterval(this.intervalId);
  }
};
</script>

<template>
  <div class="banner_pc">
    <div class="banner_pc_list">
      <div :class="activeBadge === 1 && 'banner_pc_cur'">
        <img :src="require('@/assets/banner/01.jpg')" />
      </div>
      <div :class="activeBadge === 2 && 'banner_pc_cur'">
        <img :src="require('@/assets/banner/02.jpg')" />
      </div>
      <div :class="activeBadge === 3 && 'banner_pc_cur'">
        <img :src="require('@/assets/banner/03.jpg')" />
      </div>
      <div :class="activeBadge === 4 && 'banner_pc_cur'">
        <img :src="require('@/assets/banner/04.jpg')" />
      </div>
      <div :class="activeBadge === 5 && 'banner_pc_cur'">
        <img :src="require('@/assets/banner/05.jpg')" />
      </div>
    </div>
    <div class="logo">
      <div class="en">
        CHINA ART BANK
      </div>
      <div class="zh">
        中國藝術銀行
      </div>
      <div class="des">
        中国艺术银行是一家境内外综合营业文化、艺术品交易、金融租赁、保险业务、商业银行、基金管理、进出口贸易等综合性国际金融集团.
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

@import "./index.scss";



.banner_pc .banner_pc_list {
  position: relative;
  width: 100%;
  //height: 675px;
  height: 675px;
  overflow: hidden;
}

.banner_pc .banner_pc_list div {
  position: absolute;
  width: 100%;
  text-align: center;
  list-style-type: none;
}

.banner_pc .banner_pc_list div:nth-child(1) {
  z-index: 6;
}

.banner_pc .banner_pc_list div:nth-child(2) {
  z-index: 5;
}

.banner_pc .banner_pc_list div:nth-child(3) {
  z-index: 4;
}

.banner_pc .banner_pc_list div:nth-child(4) {
  z-index: 3;
}

.banner_pc .banner_pc_list div:nth-child(5) {
  z-index: 2;
}

.banner_pc .banner_pc_list div:nth-child(6) {
  z-index: 1;
}

.banner_pc .banner_pc_list div img {
  width: auto;
  //width: 100%;
  height: 675px;
  opacity: 0;
  transform: scale(1.1);
  transform-origin: center;
  transition: opacity 3s, transform 10s;
}

.banner_pc .banner_pc_list div.banner_pc_cur img {
  z-index: 9;
  opacity: 1;
  transform: scale(1);
}



.banner_pc p,
.banner_pc span {
  display: inline-block;
  width: 100%;
  text-align: center;
  left: 0;
  z-index: 20;
  position: absolute;
  color: #fff;
}

.banner_pc p {
  font-size: 24px;
  bottom: 190px;
  letter-spacing: 4px;
}

.banner_pc span {
  font-size: 14px;
  bottom: 150px;
  letter-spacing: 4px;
}

.banner_pc .flower_video {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
}

.banner_pc .viode_none {
  width: 100%;
  text-align: center;
  position: relative;
}

.banner_pc video {
  width: 1200px;
  height: 675px;
  text-align: center;
}

.banner_pc .flower_btn {
  width: 110px;
  height: 110px;
  position: absolute;
  top: calc((100% - 110px) / 2);
  left: calc((100% - 110px) / 2);
  background: url("../../assets/banner/bouQuetChina_play.png") no-repeat
    center/100% auto;
  cursor: pointer;
  z-index: 99;
}
</style>
