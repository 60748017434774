<template>
  <div>
    <!--    轮播图-->
    <Banner />
    <!--    中国艺术银行的前世今生-->
    <Headline />
    <!--    业务.板块-->
    <BusinessSegment />
    <!--    精选艺术-->
    <Art />
    <!--    艺术活动-->
    <ArtActivity />
    <!--    新闻中心-->
    <NewsCenter />
    <div
      class="backToTop"
      :style="{ display: tabshow ? 'block' : 'none' }"
      v-on:click="backTop"
    />
  </div>
</template>

<script>
import BusinessSegment from "./BusinessSegment.vue";
import Art from "./Art.vue";
import ArtActivity from "./ArtActivity.vue";
import NewsCenter from "./NewsCenter.vue";
import Banner from "./Banner.vue";
import Headline from "./Headline.vue";

export default {
  name: "index",
  components: {
    BusinessSegment,
    Art,
    ArtActivity,
    NewsCenter,
    Banner,
    Headline
  },
  data() {
    return {
      scrollTopNum: "", //页面滚动的高度
      tabshow: false //是否进行某种操作
    };
  },
  watch: {
    scrollTopNum: function() {
      if (this.scrollTopNum > 50) {
        this.tabshow = true;
      } else {
        this.tabshow = false;
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollTopNum = top;
    },
    backTop() {
      const goToTop = () => window.scrollTo(0, 0);
      goToTop();
    }
  },
  beforeDestroy() {}
};
</script>

<style scoped lang="scss">
@import "./index.scss";
@import "../../styles/variables.scss";

.backToTop {
  position: fixed;
  bottom: 330px;
  right: 20px;
  background-color: $theme-orange;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  z-index: 99;
  width: 40px;
  height: 40px;
  background: url("../../assets/home/home_to_top.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
}
.backToTop:hover {
  background: url("../../assets/home/home_to_top_sel.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  transform: scale(1.06);
  transition: all 1s;
}
</style>
