<script>
export default {
  name: "Art",
  components: {},
  data() {
    return {
      isCheck: 3, // 选中的标签
      showList: [1, 2, 3, 4, 5], //显示的数组
      maxLength: 9 // 最大的数量
    };
  },
  mounted() {},
  methods: {
    /**
     * 切换标签
     */
    checkTag(val) {
      this.isCheck = val;
    },
    /**
     * 切换显示隐藏的图片
     */
    checkShow(direction) {
      const showListCurrent = this.showList;
      if (direction === "left") {
        const first = showListCurrent[0]-1;
        if (first >= 1) {
          showListCurrent.pop(); // 删除最后一个元素
          showListCurrent.unshift(first);// 添加元素到第一位
          this.showList = showListCurrent;
        }
      } else if (direction === "right") {
        const last = showListCurrent[showListCurrent.length - 1] + 1;
        if (last <= this.maxLength) {
          showListCurrent.shift(); // 删除第一个元素
          showListCurrent.push(last);
          this.showList = showListCurrent;
        }
      }
    }
  },
  beforeDestroy() {}
};
</script>

<template>
  <div class="art">
    <div class="title_comm_warp">
      <div class="title_comm_div_warp">
        <div class="content_comm_warp">
          {{ this.$t("home.art.title") }}
        </div>
        <div class="content_but_comm_warp" />
      </div>
    </div>
    <div class="art_content_warp">
      <el-image
        class="image_arrow"
        :src="require('@/assets/home/art_left.png')"
        fit="scale-down"
        style="margin-right: 10px"
        v-on:click="checkShow('left')"
        lazy
      />

      <div class="image_item" v-show="showList.indexOf(1) !== -1">
        <el-image
          :class="isCheck === 1 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_01.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(1)"
          lazy
        />
        <div :class="isCheck === 1 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(2) !== -1">
        <el-image
          :class="isCheck === 2 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_02.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(2)"
          lazy
        />
        <div :class="isCheck === 2 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(3) !== -1">
        <el-image
          :class="isCheck === 3 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_03.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(3)"
          lazy
        />
        <div :class="isCheck === 3 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(4) !== -1">
        <el-image
          :class="isCheck === 4 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_04.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(4)"
          lazy
        />
        <div :class="isCheck === 4 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(5) !== -1">
        <el-image
          :class="isCheck === 5 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_05.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(5)"
          lazy
        />
        <div :class="isCheck === 5 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(6) !== -1">
        <el-image
          :class="isCheck === 6 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_06.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(6)"
          lazy
        />
        <div :class="isCheck === 6 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(7) !== -1">
        <el-image
          :class="isCheck === 7 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_07.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(7)"
          lazy
        />
        <div :class="isCheck === 7 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(8) !== -1">
        <el-image
          :class="isCheck === 8 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_08.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(8)"
          lazy
        />
        <div :class="isCheck === 8 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <div class="image_item" v-show="showList.indexOf(9) !== -1">
        <el-image
          :class="isCheck === 9 ? 'select' : 'no_select'"
          :src="require('@/assets/home/art_09.png')"
          fit="scale-down"
          v-on:mouseover="checkTag(9)"
          lazy
        />
        <div :class="isCheck === 9 ? 'show' : 'no_show'">
          <el-image
            class="but_img"
            :src="require('@/assets/home/news_but.png')"
            fit="scale-down"
            lazy
          />
          <div class="but_desc">trust</div>
        </div>
      </div>

      <el-image
        class="image_arrow"
        :src="require('@/assets/home/art_right.png')"
        fit="scale-down"
        style="margin-left: 10px"
        v-on:click="checkShow('right')"
        lazy
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "index";
.art {
  width: calc(100% - 20%);
  height: 700px;
  background: url("../../assets/home/bg/art.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  padding: 0 10%;
  .art_content_warp {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .image_arrow {
      width: 60px;
      margin-top: 100px;
    }

    .image_item {
      width: 17%;
      text-align: center;
    }

    .no_select {
      transition: margin-left 0.5s ease-in-out;
      margin-top: 128px;
    }
    .select {
      //transition: width 0.5s ease, height 0.3s ease-out;
      //transition: opacity 2s;
      transition: margin-left 0.5s ease-in-out;
      margin-top: 28px;
    }
    .show {
      display: block;
    }
    .no_show {
      display: none;
    }
    .but_img {
      height: 62px;
    }
    .but_desc {
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    /deep/ {
      .el-image {
        cursor: pointer;
      }
    }
  }
}

/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1280px) {
  .art {
    height: 600px;
  }
}
/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1280px) and (max-width: 1440px) {
  .art {
    height: 600px;
  }
}
/*最小屏幕分辨率为1440px且最大为1680px*/
@media (min-width: 1441px) and (max-width: 1920px) {
  .art {
    height: 640px;
  }
}
/*最小屏幕分辨率为2560px*/
@media (min-width: 1921px) and (max-width: 2560px) {
  .art {
    height: 750px;
  }
}

/*最小屏幕分辨率为2560px*/
@media (min-width: 2561px){
  .art {
    height: 850px;
  }
}
</style>
