import { render, staticRenderFns } from "./NewsCenter.vue?vue&type=template&id=040c2206&scoped=true"
import script from "./NewsCenter.vue?vue&type=script&lang=js"
export * from "./NewsCenter.vue?vue&type=script&lang=js"
import style0 from "./NewsCenter.vue?vue&type=style&index=0&id=040c2206&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040c2206",
  null
  
)

export default component.exports