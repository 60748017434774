<script>
export default {
  name: "Headline",
  data() {
    return {
      isVideo: false
    };
  },
  mounted() {},
  methods: {
    checkVideo(val) {
      this.isVideo = val;
    },
    onVideoEnded() {
      this.checkVideo(false);
    },
    // 跳转到详情
    jumpInfo() {
      this.$router.push({ path: "/home/learnMore" });
    }
  }
};
</script>

<template>
  <div class="headline wrapper">
    <div class="headline_div">
      <div class="nowVideo">
        <img
          :src="require('@/assets/home/headine_video_fm.png')"
          v-on:click="checkVideo(true)"
          v-show="!isVideo"
        />

        <div v-if="isVideo">
          <video
            :src="require('@/assets/home/headline_video.mp4')"
            controls
            autoplay
            ref="videoPlayer"
            @ended="onVideoEnded"
          ></video>
        </div>
      </div>
      <div class="headline_inform" v-on:click="jumpInfo">
        <span class="title">
          中国艺术银行的前世今生
        </span>
        <div class="headline_con">
          一时，弟子合掌恭敬而白佛言:“何为佛法 ?"<br />
          佛曰:"佛法简称为佛陀的教育方法。<br />
          又曰:“如是佛法，即非佛法，名为佛法。”弟子顿悟!可否将佛法视为万物 ?<br />
          又问:如是说“中国艺术银行”即非“中国艺术银行”是名“中国艺术银行“。因为今天在中国<br />
          即称“中国艺术银行”明天在美国 又称“美国艺术银行”。<br />
          它即不是“中国“，又不是“美国”，只是假名而已。 佛言:"善哉!善哉!“<br />
          近日，疯传:“WWW.中国艺术银行.COM”,有连夜入网，先睹为快！众人皆喜悦。<br />
        </div>
        <div class="info" v-on:click="jumpInfo">
          了解详情
          <el-image
            :src="require('@/assets/home/headine_learn_more.png')"
            fit="scale-down"
            lazy
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.headline {
  display: flex;
  margin-top: -126px;
  justify-content: center;
}

.headline_div {
  background: #fff;
  z-index: 50;
  display: flex;
  position: relative;
  box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, 0.4);
}

.nowVideo {
  //width: 900px;
  //height: 450px;
  position: relative;
  cursor: pointer;
}

.headline .nowVideo img {
  width: 100%;
  transform: scale(1);
  transition: all 0.8s;
}
.nowVideo img:hover {
  transform: scale(1.1);
}

//.headline .nowVideo span {
//  width: 80px;
//  height: 80px;
//  background: url("../../assets/banner/headline_play.png") no-repeat center/100%
//    auto;
//  display: inline-block;
//  position: absolute;
//  top: calc((100% - 80px) / 2);
//  left: calc((100% - 80px) / 2);
//  cursor: pointer;
//}

.headline .nowVideo video {
  width: 100%;
  height: 100%;
}

.headline .headline_inform {
  width: 400px;
  flex-wrap: wrap;
  align-items: center;
  color: #464a46;
  box-sizing: border-box;

  .headline_con {
    letter-spacing: 1px;
  }
  .info {
    color: $theme-red;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .info:hover {
    transform: scale(1.02);
    transition: all 1s;
  }
  .title {
    cursor: pointer;
  }
}
.headline .headline_inform a {
  color: #000000;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: 2px;
}

.headline .headline_inform h2 {
  font-size: 40px;
  margin-bottom: 45px;
  line-height: 45px;
}

.headline .headline_inform p {
  font-size: 16px;
  line-height: 30px;
}

.none {
  display: none;
}

/*最大屏幕分辨率为1280px*/
@media screen and (max-width: 1279px) {
  .nowVideo {
    width: 528px;
    height: 248px;
  }
  .headline_inform {
    padding: 14px 20px 12px;
  }
  .headline_inform .title {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .headline_inform .headline_con {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-top: 6px;
  }
  .info {
    margin-bottom: 4px;
    /deep/ {
      .el-image {
        margin: 8px 0 0px 6px;
        width: 28px;
        img {
          margin-top: 6px;
        }
      }
    }
  }
}
/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1280px) and (max-width: 1979px) {
  .nowVideo {
    width: 649px;
    height: 348px;
  }
  .headline_inform {
    padding: 22px 20px 12px;
  }
  .headline_inform .title {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .headline_inform .headline_con {
    font-size: 0.8rem;
    line-height: 1.3rem;
    margin-top: 10px;
  }
  .info {
    margin-bottom: 4px;
    /deep/ {
      .el-image {
        margin: 8px 0 0px 6px;
        width: 28px;
        img {
          margin-top: 6px;
        }
      }
    }
  }
}
/*最小屏幕分辨率为1280px且最大为1440px*/
@media (min-width: 1980px) and (max-width: 2560px) {
  .nowVideo {
    width: 900px;
    height: 450px;
  }
  .headline_inform {
    padding: 28px 20px 12px;
  }
  .headline_inform .title {
    font-size: 1.6rem;
    line-height: 4.2rem;
  }
  .headline_inform .headline_con {
    font-size: 0.9rem;
    line-height: 1.7rem;
    margin-top: 10px;
  }
  .info {
    /deep/ {
      .el-image {
        margin: 30px 0 0px 10px;
        width: 60px;
      }
    }
  }
}
/*最小屏幕分辨率为2560px*/
@media screen and (min-width: 2561px) {
  .nowVideo {
    width: 900px;
    height: 450px;
  }
  .headline_inform {
    padding: 28px 20px 12px;
  }
  .headline_inform .title {
    font-size: 1.6rem;
    line-height: 4.2rem;
  }
  .headline_inform .headline_con {
    font-size: 0.9rem;
    line-height: 1.7rem;
    margin-top: 10px;
  }
  .info {
    /deep/ {
      .el-image {
        margin: 30px 0 0px 10px;
        width: 60px;
      }
    }
  }
}
</style>
